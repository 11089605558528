import './App.css';
import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify'; // Import the ToastContainer component
import 'react-toastify/dist/ReactToastify.css'; // Import the toast styles
import LoadingButton from './pages/LoadingButton';

const Header = React.lazy(() => import('./components/header/Header'));
const Home = React.lazy(() => import('./pages/Home'));
const ATVPackage = React.lazy(() => import('./pages/ATVPackage'));
const Gallery = React.lazy(() => import('./pages/Gallery'));
const Booking = React.lazy(() => import('./pages/Booking'));
const BookingCart = React.lazy(() => import('./pages/BookingGoCart'));
const DetailPayment = React.lazy(() => import('./pages/DetailPayment'));
const Invoice = React.lazy(() => import('./pages/InvoicePayment'));
const InvoiceParams = React.lazy(() => import('./pages/InvoicePaymentParams'));
const NotFound = React.lazy(() => import('./pages/NotFound'));

function App() {

  return (
    <div>
      {/* Header will be displayed on all routes */}
      <BrowserRouter>
        <Header />
        {/* Add the ToastContainer component */}
        <ToastContainer />
        <Suspense fallback={<LoadingButton />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/atv_package" element={<ATVPackage />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/booking-go-cart" element={<BookingCart />} />
            <Route path="/detail_invoice" element={<DetailPayment />} />
            <Route path="/invoice" element={<Invoice />} />
            <Route path="/wis/invoice" element={<InvoiceParams />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;